export const COLOR_CLASSES = {
  NEUTRAL: {
    900: 'n___900',
    800: 'n___800',
    700: 'n___700',
    600: 'n___600',
    500: 'n___500',
    400: 'n___400',
    300: 'n___300',
    200: 'n___200',
    100: 'n___100',
    50: 'n___50',
    0: 'n___0',
  },
  BLUE: {
    500: 'b___500',
    400: 'b___400',
    300: 'b___300',
    100: 'b___100',
  },
  RED: {
    500: 'r___500',
    400: 'r___400',
    300: 'r___300',
    100: 'r___100',
  },
  GREEN: {
    500: 'g___500',
    400: 'g___400',
    300: 'g___300',
    100: 'g___100',
  },
  YELLOW: {
    500: 'y___500',
    400: 'y___400',
    300: 'y___300',
    100: 'y___100',
  },
  PURPLE: {
    300: 'p___300',
  },
  ORANGE: {
    300: 'o___300',
  },
};

export const COLOR_NAMES = {
  BLACK: 'black',
  RED: 'red',
  BLUE: 'blue',
  LIGHT_BLUE: 'lightblue',
  GREEN: 'green',
  YELLOW: 'yellow',
  PURPLE: 'purple',
  ORANGE: 'orange',
  WHITE: 'white',
  GRAY: 'gray',
  DARK_YELLOW: 'darkyellow',
  DARK_GREEN: 'darkgreen',
  DARK_BLUE: 'darkblue',
};
